import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { FormControl, TextField, Box, Popper, Hidden } from '@material-ui/core'
import classNames from 'classnames'
import Icon from '@system/icon'

const useStyles = makeStyles((theme) => ({
  filter: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.text.secondary,
    },
    '& .MuiFormLabel-filled': {
      display: 'none',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(12px, 6px) scale(1)',
      [theme.breakpoints.up('md')]: {
        transform: 'translate(12px, 7px) scale(1)',
      },
      [theme.breakpoints.up('lg')]: {
        transform: 'translate(12px, 8px) scale(1)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
  },
  filterInput: {
    height: '32px',
    color: theme.palette.text.secondary,
    '&>div': {
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&>svg': {
      fontSize: '1.5rem',
      color: theme.palette.background.slate,
    },
  },
  textField: {
    width: '100%',
    '& input': {
      padding: '7px 10px',
      height: '32px',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.common.white,
    },
  },
  option: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  searchIcon: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    height: '30px',
    width: '30px',
    color: theme.palette.background.slate,
    fontSize: '15px',
  },
  activeSearch: {
    color: theme.palette.primary.main,
  },
  searchIconSvg: {
    height: '2rem !important',
    width: '2rem !important',
    cursor: 'pointer',
  },
  popper: {
    top: 'calc(100% + 13px) !important',
    right: '-16px',
    minWidth: '235px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '8px',
    backgroundColor: theme.palette.background.paper,
    transform: 'none !important',
    left: 'auto !important',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '16px',
      paddingRight: '16px',
      left: '-16px !important',
      minWidth: '100%',
      right: '-16px',
    },
  },
}))

const SearchFilter = (props) => {
  const { placeholderText, onChangeSearchText, searchValue } = props.blok
  const classes = useStyles()
  const [isSearchInputVisible, setSearchInputVisibility] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [placement, setPlacement] = useState()

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
    setSearchInputVisibility(!isSearchInputVisible)
  }

  return (
    <SbEditable content={props.blok} className={classes.filter}>
      <Box>
        <Hidden smDown>
          <FormControl variant="outlined" className={classes.filter}>
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              disablePortal={true}
              className={classes.popper}
            >
              <Box>
                <TextField
                  placeholder={placeholderText}
                  variant="outlined"
                  type="text"
                  value={searchValue || ''}
                  className={classes.textField}
                  onChange={(event) => onChangeSearchText(event)}
                />
              </Box>
            </Popper>
            <Box
              onClick={handleClick('bottom-end')}
              className={classNames(classes.searchIcon, {
                [classes.activeSearch]: isSearchInputVisible,
              })}
            >
              <Icon styles={classes.searchIconSvg}>SearchIcon</Icon>
            </Box>
          </FormControl>
        </Hidden>
      </Box>
    </SbEditable>
  )
}

export default SearchFilter
